import { makeStyles } from "@material-ui/styles";
import { useGetFirewallByIdQuery } from "../../../../redux/services/firewalls-service";
import GeneralInfoOverview from "./OverviewComponents/GeneralInfoOverview";
import SecurityPoliciesOverview from "./OverviewComponents/SecurityPoliciesOverview";
import EgressNatOverview from "./OverviewComponents/EgressNatOverview";
import UserIdOverview from "./OverviewComponents/UserIdOverview";
import EndpointsOverview from "./OverviewComponents/EndpointsOverview";
import LogsMetricsOverview from "./OverviewComponents/LogsMetricsOverview";
import { useTranslate } from "../../../../customHooks";
import { toast } from "../../../../components";
import { LoadingPanel } from "@panwds/react-ui";
import { firewallStyles } from "../../style";

interface OverviewFirewallProps {
    fwid: string;
    region: string;
}

const useStyles = makeStyles((theme) => ({
    overviewFirewallContainer: {
        margin: '20px'
    },
    overviewFirewallSubContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '20px',
        marginTop: '20px'
    },
    overviewFirewallTitle: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '20px',
        color: '#333333',
    },
    title: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '20px',
        color: '#333333',
        marginBottom: '20px'
    },
    flexContainer: {
        flex: 1,
        marginRight: '20px'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    flexRow: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px'
    },
    flexRowHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '14.4px',
        color: '#707070',
        flex: '1'
    },
    flexRowValues: {
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '12px',
        lineHeight: '16px',
        color: '#333333',
        textAlign: 'left',
        flex: '1'
    },
    truncatedText: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '150px',
        marginRight: '8px'
    },
    truncatedContainer: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: '200px'
    }
}));

const FirewallOverview= (props: {firewallData: Record<string, any>, isLoading: boolean, refreshFirewallData: () => void}) => {
    const classes = useStyles();
    const translate = useTranslate();
    const { firewallData, isLoading, refreshFirewallData } = props;
    const firewall = firewallData?.Firewall;
    const status = firewallData?.Status;
    const fwclasses = firewallStyles();

    // Determine the managed by value
    let managedBy = "Default Rulestack";
    if (firewall?.RuleStackName) {
        managedBy = "Local Rulestack";
    } else if (firewall?.GlobalRuleStackName) {
        managedBy = "Global Rulestack";
    } else if (firewall?.LinkId) {
        managedBy = firewall.LinkId.includes("Link-SCM") ? "SCM" : "Panorama";
    }

    return (
        <div className="tw-relative">
            {props?.isLoading &&
                <div className="tw-absolute tw-inset-0 tw-right-0 tw-flex tw-justify-center tw-items-center tw-backdrop-blur-sm tw-z-50" style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }}>
                    <div className="tw-w-6 tw-h-6">
                        <LoadingPanel />
                    </div>
                </div>
            }
            <div className={fwclasses.fwEditHeader}>{translate(`resources.firewallsV2.overview`)}</div>
            <div className={classes.overviewFirewallSubContainer}>
                <GeneralInfoOverview firewall={firewall} status={status} styles={classes} />
                <SecurityPoliciesOverview firewall={firewall} status={status} managedBy={managedBy} styles={classes} />
            </div>
            <div className={classes.overviewFirewallSubContainer}>
                <EgressNatOverview firewall={firewall} styles={classes} />
                <UserIdOverview firewall={firewall} styles={classes} />
            </div>
            <div className={classes.overviewFirewallSubContainer}>
                <EndpointsOverview firewall={firewall} styles={classes} />
                <LogsMetricsOverview firewall={firewall} styles={classes} />
            </div>
        </div>
    );
};

export default FirewallOverview;
