import React, { ReactElement, ReactNode} from "react";
import { Form } from 'react-final-form'
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import arrayMutators from "final-form-arrays";

const useStyles = makeStyles((theme) => {
    return {
        footer: {
            justifyContent: "flex-end",
            display: "flex",
            alignItems: "center",
            height: "64px",
            borderTop: "1px solid #DADBDB",
            "& button": {
                marginRight: "16px",
                padding: "16px",
            },
            position: 'fixed',
            width: '100%',
            maxWidth: '725px',
            zIndex: 1000,
        },
        customSheetBox: {
            padding: '16px',
            flexDirection: "column",
            display: "flex",
        },
        sheetContainer: {
            padding: '16px',
            flexDirection: "column",
            height: 'calc(90vh - 64px)',
            display: "flex",
            overflowY: 'auto',
        },
        customFormBox: (props: any) => ({
            ...props.footerStyle
        })
    }
});

export const PANWDSForm = (props: FormProps) => {
    const {
        children,
        className,
        component: Component,
        onSubmit,
        toolbar,
        style,
        validate,
        initialValues = {},
        requireStylingFooter,
        footerStyle,
        sheetStyle,
        customMutators,
    } = props;
    const classes = useStyles({footerStyle});
    return (
        <Form onSubmit={(values, form) => onSubmit(values, form)} validate={validate} initialValues={initialValues} mutators={{ ...customMutators, ...arrayMutators }} render={(renderProps) => {
            const { handleSubmit, form, submitting, pristine, values, invalid } = renderProps;
            return (
                requireStylingFooter ?
                    <form onSubmit={handleSubmit} className={classnames(className)} style={style}>
                        <div className={sheetStyle ? classes.customSheetBox : classes.sheetContainer}>{children}</div>
                        <div className={footerStyle ? classes.customFormBox : classes.footer}>
                            {toolbar &&
                                React.cloneElement(toolbar, {
                                    ...renderProps
                                })}
                        </div>
                    </form>
                    :
                    <form onSubmit={handleSubmit} className={classnames(className)} style={style}>
                        {children}
                        {toolbar &&
                            React.cloneElement(toolbar, {
                                ...renderProps
                            })}
                    </form>
            );
        }}></Form>
    );
};

export interface FormProps {
    children: ReactNode;
    onSubmit: any;
    toolbar: ReactElement | false;
    className?: string;
    style?: any;
    component?: React.ComponentType<any>;
    initialValues?: any;
    validate?: any;
    requireStylingFooter? : boolean;
    footerStyle?: any;
    sheetStyle?: any;
    customMutators?: any;
}
