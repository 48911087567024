import { LoadingButton, Modal, ModalHeader } from "@panwds/react-ui";
import React, { useEffect, useState } from "react";
import logo from "../../images/panw_logo.png";
import { Title } from "../styles";
import { makeStyles } from "@material-ui/core/styles";
import EventEmitter from "../../eventEmitter";
import { ApplicationConfigManager } from "../../types";
import { dataProvider } from "../../dataProvider";
import * as DataTypes from "../../api/FwaasDataTypes";
import { FormControl } from "@material-ui/core";
import { Field, withTypes } from "react-final-form";
import {
    PANWDSSelect,
} from "../../components/PANWDSElements";
import { toast } from "../../components";
import { useHistory } from "react-router-dom";
import { RouteUri } from "../../routeUri";
import { useTranslate } from "../../customHooks";
import CircularLoader from "../../components/CircularLoader/CircularLoader";

const useStyles = makeStyles((theme) => ({
    registrationModal: {
        padding: '30px 24px 50px'
    },
    modalHeader: {
        border: "none"
    },
    modalBody: {
        padding: "50px 0"
    },
    avatar: {
        paddingBottom: "12px",
        display: "flex",
        justifyContent: "center",
    },
    form: {
        padding: "0 1em 1em 1em",
        height: "100%",
        width: "100%",
    },
    formControl: {
        marginTop: "7px",
        width: "100%",
    },
    actions: {
        justifyContent: "space-between",
        padding: 0,
        marginTop: 10,
    },
    logo: {

    },
    loadingBlur: {
        filter: "blur(1px)",
    },
    btnGroup: {
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        gridGap: "15px",
    },
    toolbar: {
        background: "transparent",
        display: "flex",
        gap: theme.spacing(1),
        justifyContent: "end",
        "-webkit-justify-content": "flex-end",
        alignItems: "flex-start",
        boxSizing: "border-box",
    },
}));

export default ({ openModal }: any) => {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tenantChoices, setTenantChoices] = useState<
        any[] | undefined
        >([]);
    const [tenantVersions, setTenantVersions] = useState<any>({});
    const history = useHistory();
    const translate = useTranslate();

    useEffect(() => {
        EventEmitter.on('multiTenantModal', handleSetTenant);
        return () => {
            EventEmitter.off('multiTenantModal', handleSetTenant);
        }
    }, [openModal]);

    const handleSetTenant = (payload: any) => {
        if (!isOpen) {
            setIsLoading(true);
            const idpC = ApplicationConfigManager?.cognitoInstance?.getConfig();
            dataProvider.get("subscription", {
                data: {
                    LoginType: (idpC?.user) ? "IdpC" : "IdpO"
                }
            }).then(async (response: DataTypes.IFwaasApiResponse) => {
                if (response.data) {
                    if (response.data.length > 1) {

                        setTenantChoices(
                            response.data.map((data: any) => ({
                                text: (data?.CspSerialNumber) ? `${data?.LinkAccountId} (CSP: ${data?.CspSerialNumber})` : data?.LinkAccountId,
                                value: data?.SubscriptionKey,
                            }))
                        );
                        let versions: any = {};
                        response.data.forEach((tenant: any) => {
                            versions[tenant.SubscriptionKey] = tenant?.TenantVersion || 'v1';
                        });
                        setTenantVersions(versions);
                        setIsOpen(!isOpen);
                        setIsLoading(false);
                    } else {
                        if (response.data[0] && response.data[0].SubscriptionKey) {
                            await handleOnSetTenant({ "TenantId": response?.data[0]?.SubscriptionKey, "TenantVersion": response?.data[0]?.TenantVersion });
                        } else {
                            toast.error(translate("multiTenantModal.noTenantError"));
                            ApplicationConfigManager.getInstance().clearSession();
                            history.replace(RouteUri.Login);
                        }
                    }
                }
                else {
                    toast.error(translate("multiTenantModal.noTenantError"));
                    ApplicationConfigManager.getInstance().clearSession();
                    history.replace(RouteUri.Login);
                }
            }).catch((e: any) => {
                toast.error(e?.error, { toastId: "multitenant-get" });
            }).finally(() => {
                setIsLoading(false);
            });
        }

    }

    const handleOnCancel = () => {
        setIsOpen(false);
    }

    const handleOnSetTenant = async (values: any) => {

        var newAppConfig = ApplicationConfigManager.getInstance().getConfig();
        let tenant = values?.TenantId;
        newAppConfig.xApiKey = tenant;
        let tenantVersion = values.TenantVersion || tenantVersions[values.TenantId] || 'v1';
        newAppConfig.tenantVersion = tenantVersion

        sessionStorage.setItem("tenantVersion", tenantVersion);
        sessionStorage.setItem("tenant", tenant);

        await ApplicationConfigManager.getInstance().loadConfig()

        setIsOpen(false);
        history.replace(RouteUri.Dashboard);

    }

    if (!isOpen) {
        if (isLoading) {
            return <CircularLoader loading={isLoading} />;
        }
        return <div className="okta-modal"></div>;
    }

    const Toolbar = (toolbarProps: any) => {
        const { submitting, pristine } = toolbarProps;

        return (
            <div className={classes.toolbar}>
                <LoadingButton
                    dataResult={{
                        loading: !!submitting,
                        success: true
                    }}
                    size="md"
                    appearance="primary"
                    type="submit"
                    disabled={submitting || pristine}
                    dataMetrics="cloudngfw-multitenant-save-button"
                >
                    {translate("multiTenantModal.submit")}
                </LoadingButton>
            </div>
        )
    };

    interface FormValues {
        TenantId?: string;
    }

    const { Form } = withTypes<FormValues>();

    return (
        <Modal isOpen={isOpen} onClose={handleOnCancel} style={{ width: '448px', maxHeight: "100%" }}>
            <ModalHeader enableClose={false} addClassName={classes.modalHeader} />
            <div className={classes.registrationModal}>
                <div className={classes.avatar}>
                    <img src={logo} alt="logo" className={classes.logo} />
                </div>
                <Title>{translate("multiTenantModal.title")}</Title>
                <Form
                    onSubmit={handleOnSetTenant}
                    render={(formProps) => {
                        let { handleSubmit, values } = formProps;
                        return (
                            <form onSubmit={handleSubmit}>
                                <FormControl className={classes.formControl}>
                                    <Field
                                        name="TenantId"
                                        // @ts-ignore
                                        component={PANWDSSelect}
                                        items={tenantChoices}
                                        dataMetrics="cloudngfw-multitenant-tenant"
                                        loading={!tenantChoices}
                                    />
                                </FormControl>
                                <Toolbar {...formProps} />
                            </form>
                        );
                    }}
                />
            </div>
        </Modal>
    );
};
